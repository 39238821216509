<nav class="primary-nav-bar" id="leftNav">
  <div
    class="ts-logo">
    <a [routerLink]="['']">
    </a>
  </div>
  <div class="text-center">
    <div class="p-12"></div>
    <div class="dropright mouse-over" (mouseover)="displayGlobalAdd=true" (mouseleave)="displayGlobalAdd=false">
      <a class="primary-icon p-0 h-unset" href="">
      <div>
        <i class="fa-plus-filled"></i>
      </div>
    </a>
    <app-global-add
      *ngIf="authGuard?.session?.user && displayGlobalAdd"
      (toggleClickedState)="displayGlobalAdd = $event"
      class="global-add-dropdown mt-n10"></app-global-add>
    </div>
    <div class="p-6"></div>
  </div>
  <div>
    <div class="pt15 mb-30 border-bottom-1 dark-green w-100">
    </div>
    <div class="text-center dropright mouse-over" (mouseover)="primaryIconHover=true" (mouseleave)="primaryIconHover=false">
      <ul class="primary-nav mt-0" id="ul_primary_menu">
        <li
          (mouseover)="isHoveringItem('dashboard')"
          [routerLink]="['/dashboard']" [routerLinkActive]="'active'">
      <a
        (click)="isIconItemClicked()"
        [routerLink]="['/dashboard']" [routerLinkActive]="'active'">
        <span>
            <i class="fa-dashboard"></i>
        </span>
      </a>
    </li>
        <app-test-dev-icon
          (mouseover)="isHoveringItem('td')"
          (click)="isIconItemClicked()"
          class="d-block"></app-test-dev-icon>
        <li
          (mouseover)="isHoveringItem('workspaces')"
          [routerLink]="['/workspaces']" [routerLinkActive]="'active'">
      <a
        (click)="isIconItemClicked()" [routerLink]="['/workspaces']" [routerLinkActive]="'active'">
        <span>
            <i class="fa-suitcase"></i>
        </span>
      </a>
    </li>
    <!-- <li
      (mouseover)="isHoveringItem('agents')"
      [routerLink]="['/agents']" [routerLinkActive]="'active'">
      <a
        (click)="isIconItemClicked()" [routerLink]="['/agents']" [routerLinkActive]="'active'">
        <span>
            <i class="fa-agent"></i>
        </span>
      </a>
    </li> -->
    <!-- <li
      (mouseover)="isHoveringItem('addons')"
      [routerLinkActive]="'active'" [routerLink]="['/addons']">
      <a
        (click)="isIconItemClicked()" [routerLinkActive]="'active'" [routerLink]="['/addons']">
        <span>
         <i class="fa-custom-functions-alt"></i>
        </span>
      </a>
    </li> -->
    <li
      (mouseover)="isHoveringItem('settings')"
      [routerLink]="['/settings']" [routerLinkActive]="'active'">
      <a
        (click)="isIconItemClicked()" [routerLink]="['/settings']" [routerLinkActive]="'active'">
        <span>
            <i class="fa-settings"></i>
        </span>
      </a>
    </li>
  </ul>
    <app-left-nav-hover
      *ngIf="authGuard?.session?.user && primaryIconHover"
      [currentHoverItem]="currentHoverItem"
      (isClicked)="primaryIconHover = $event"
      class="global-add-dropdown mt-n8"></app-left-nav-hover>
    </div>
  </div>

  <div class="profile-action-section">
    <div
      class="primary-nav mt-0" >
      <!-- <app-help-actions
        [authGuard]="authGuard"
        [isNav]="true"
        class="help-action-container"></app-help-actions>
      <li [class.active]="moreAction" class="help-more-icon">
        <a #moreActionTrigger="cdkOverlayOrigin" (click)="moreActionOptions()"
           [style.pointer-events]="moreAction ?'none': 'all'"
           [class.active]="moreAction" [matTooltip]="'hint.message.common.more_action' | translate" cdkOverlayOrigin
           placement="right">
          <span><i class="fa-ellipsis-h"></i></span>
        </a>
      </li>

    <ng-template
      #moreActionRef="cdkConnectedOverlay"
      [cdkConnectedOverlayOffsetX]="50"
      [cdkConnectedOverlayOffsetY]="-0"
      [cdkConnectedOverlayOpen]="moreAction"
      [cdkConnectedOverlayOrigin]="moreActionTrigger"
      cdkConnectedOverlay
      cdkConnectedOverlayHasBackdrop="false">
      <div [@collapse]="moreAction"
           [@expand]="moreAction"
           class="profile-action-section">
        <div (click)="moreAction=false" class="highlight-child-on-hover">
          <app-help-actions [authGuard]="authGuard"   (onOpenChat)="openChat()" (onOpenHelpDropDown)="onCloseDropdown()"></app-help-actions>
        </div>
      </div>
    </ng-template> -->
    </div>
    <ul class="primary-nav"
    *ngIf="!isNoAuth"
    >
      <li
        (click)="logout()">
        <a
          (click)="logout()"
          [matTooltip]="'logout' | translate"
          placement="right">
        <span>
         <i class="fa-sign-out-alt-solid"></i>
        </span>
        </a>
      </li>
    </ul>
  </div>
</nav>
